import React, { useEffect } from "react";
import ServicesSlider from '../../../../../components/sliders/servicesSlider/ServicesSlider'
import { useDispatch, useSelector } from "react-redux";
import { getDataCorporates } from "../../../../../app/solutions/corporates/corporatesSlice";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../components/utils/portal/loading/Loading";
import Error from "../../../../../components/utils/portal/error/Error";

function Slider() {

   const dispatch = useDispatch();
   const { isLoading, corporates, error } = useSelector((state) => state.corporates);
   
   useEffect(() => {
     dispatch(getDataCorporates());
   }, []);
   // change language handler
   const { t } = useTranslation();
   // loading
   if (isLoading) {
     return <Loading />;
   }
   // error
   if (error) {
     return <Error error={error.message} />;
   }

   
  return (
    <section className='slider pb-lg-0 pb-5'>
      <ServicesSlider data={corporates?.result?.services} id={corporates?.result?.services?.length} />
      {/* title={t("CorporatesServices")}  */}
    </section> 
  )
}

export default Slider