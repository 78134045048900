import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../api";


export const getDataBanner = createAsyncThunk("groupOverview/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/headSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurPurpose = createAsyncThunk("groupOverview/getDataOurPurpose", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/purposeSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataWhatWeDo = createAsyncThunk("groupOverview/getDataWhatWeDo", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/whatwedoSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurBrings = createAsyncThunk("groupOverview/getDataOurBrings", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/whatwebrings");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataValue = createAsyncThunk("groupOverview/getDataValue", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/values");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurFigures = createAsyncThunk("groupOverview/getDataOurFigures", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/figures");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataHaedOurPartners = createAsyncThunk("groupOverview/getDataHaedOurPartners", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/OverViewPartnersHeadSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getDataOurPartners = createAsyncThunk("groupOverview/getDataOurPartners", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/About/GetOverViewPartners");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
const initialState = {
  banner: [],
  ourPurpose: [],
  whatWeDo: [],
  ourBrings: [],
  values: [],
  ourFigures: [],
  haedOurPartners: [],
  ourPartners: [],
  isLoading: false,
  error: null,
};

export const groupOverviewSlice = createSlice({
  name: "groupOverview",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataOurPurpose.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurPurpose.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourPurpose = action.payload;
      })
      .addCase(getDataOurPurpose.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataWhatWeDo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataWhatWeDo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.whatWeDo = action.payload;
      })
      .addCase(getDataWhatWeDo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataOurBrings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurBrings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourBrings = action.payload;
      })
      .addCase(getDataOurBrings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataValue.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataValue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.values = action.payload;
      })
      .addCase(getDataValue.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataOurFigures.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurFigures.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourFigures = action.payload;
      })
      .addCase(getDataOurFigures.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataHaedOurPartners.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataHaedOurPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.haedOurPartners = action.payload;
      })
      .addCase(getDataHaedOurPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataOurPartners.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourPartners = action.payload;
      })
      .addCase(getDataOurPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default groupOverviewSlice.reducer;
