import React from "react";
import ContactUsSection from "../../../../../../components/contactUs/ContactUs";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

function Contact() {
  const { t } = useTranslation();

  return (
    <section className={`contctUs pb-5`}>
      <Container>
        <ContactUsSection
          contact={true}
          desc={t("Letusassistyouinadvancingyourlearningexperince")}
          moreText={true}
        />
      </Container>
    </section>
  );
}

export default Contact;

