import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const getDataBanner = createAsyncThunk("history/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/History/headSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurHistory = createAsyncThunk("history/ourHistory", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/History/historystorySection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDatatimeline = createAsyncThunk("history/getDatatimeline", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/History/historyyears");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const initialState = {
  banner: [],
  ourHistory: [],
  timeline: [],
  isLoading: false,
  error: null,
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataOurHistory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ourHistory = action.payload;
      })
      .addCase(getDataOurHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDatatimeline.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDatatimeline.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeline = action.payload;
      })
      .addCase(getDatatimeline.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default historySlice.reducer;
