import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import HalfSection5 from "../../../components/halfSection5/HalfSection5";
import HalfSection6 from "../../../components/halfSection6/HalfSection6";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Loading from "../../../components/utils/portal/loading/Loading";
import Error from "../../../components/utils/portal/error/Error";
import { useDispatch, useSelector } from "react-redux";
import MainBanner from "../../../components/mainBanner/MainBanner";
import LineBox from "../../../components/boxes/lineBox/LineBox";
import NumberBox from "../../../components/boxes/numberSection/NumberBox";

function ProjectDetails() {
  const param = useParams();

  const [latestProjectSection, setLatestProjectSection] = useState(null);


  const getDataProjects = createAsyncThunk("socialImpact/getDataProjects", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `https://backend.nahdetmisr.com:10443/api/ProjectsPage/GetProject?id=${param.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

  // const getLatestProjectSection = async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `https://nahdatmasr.azurewebsites.net/api/Projects/GetLatestProjectSection?id=${param.id}`
  //     );
  //     setLatestProjectSection(response.data);
  //   } catch (error) {
  //     console.error("Error fetching latest project section:", error);
  //   }
  // };

  const getLatestProjectSection = async () => {
    try {
      const response = await axios.get(
        `https://nahdatmasr.azurewebsites.net/api/Projects/GetLatestProjectSection?id=${param.id}`
      );
      setLatestProjectSection(response.data.result);
    } catch (error) {
      console.error("Error fetching latest project section:", error);
    }
  };

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, projects, error } = useSelector((state) => state.socialImpact);
  useEffect(() => {
    dispatch(getDataProjects());
  }, []);

  useEffect(() => {
    dispatch(getDataProjects());
    getLatestProjectSection();
  }, [dispatch, param.id]);


  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }

  console.log(projects?.result?.projectHeader);

  const titleAr = projects?.result?.projectHeader?.titleAr || "";

  const cleanTitleAr = titleAr.replace(/\\/g, "");
  
  const linesAr = [
    `${cleanTitleAr.split(" ")[0]} ${cleanTitleAr.split(" ")[1]}`, 
    cleanTitleAr.split(" ")[2],                                   
    cleanTitleAr.split(" ").slice(3).join(" ")
  ]; 
  const renderTitle = () => {
    if (i18n.language === "ar") {
      if (projects?.result?.id === 21) {
        // Special formatting for project ID 21
        return (
          <>
            {`${projects?.result?.projectHeader?.titleAr || ""}`
              .replace(/\\/g, "")
              .split(" ")
              .reduce((lines, word, index) => {
                if (index < 2) lines[0] += `${word} `; 
                else if (index === 2) lines[1] = word; 
                else lines[2] += `${word} `; 
                return lines;
              }, ["", "", ""])
              .map((line, idx) => (
                <React.Fragment key={idx}>
                  {line.trim()}
                  {idx < 2 && <br />}
                </React.Fragment>
              ))}
          </>
        );
      } else {
        // Default formatting for other projects
        return projects?.result?.projectHeader?.titleAr?.replace(/\\/g, "");
      }
    }
    return projects?.result?.projectHeader?.titleEn;
  };
  return (
    <>
      <section className="banner">
        <MainBanner
          background={projects?.result?.projectHeader?.image}
          title={renderTitle()}
          titleClassName="w-50I"
 // Pass the class only to the title

          // subTitle={`${
          //   i18n.language === "en"
          //     ? projects?.result?.projectHeader?.shortDescriptionEn
          //     : projects?.result?.projectHeader?.shortDescriptionAr
          // }`}
        />
      </section>
      {projects?.result?.id === 10 && (
        <section className="projectDetailsDescriptionDTOs">
          <Container>
            {projects?.result?.projectDetailsDescriptionDTOs?.map((project) => {
              return (
                <div className="evenOddSection" key={project.id}>
                  <HalfSection6
                    key={project?.id}
                    img={project?.image}
                    title={project?.titleEn && `${i18n.language === "en" ? project?.titleEn : project?.titleAr}`}
                    desc={`${
                      i18n.language === "en"
                        ? project?.descriptionEn.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "")
                        : project?.descriptionAr.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "")
                    }`}
                    className="whtBg mb-4"
                    logo={true}
                    logoSrc={project?.projectDetailsDescriptionLogosDTOs.map((img) => img.image)}
                    internal={true}
                    col1Lg={6}
                    col2Lg={4}
                  />
                </div>
              );
            })}
          </Container>
        </section>  
      )}
      {projects?.result?.id !== 10 && (
        <section className="projectDetailsDescriptionDTOs">
          <Container>
            {projects?.result?.projectDetailsDescriptionDTOs?.map((project) => {
              return (
                <div className="evenOddSection" key={project.id}>
                  <HalfSection5
                    key={project.id}
                    img={project.image}
                    title={project.titleEn && `${i18n.language === "en" ? project.titleEn : project.titleAr}`}
                    desc={
                      i18n.language === "en"
                        ? project.descriptionEn.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "")
                        : project.descriptionAr.replace(/<p>(&nbsp;|\s|<br\s*\/?>)*<\/p>/g, "")
                    }
                    className="whtBg mb-4"
                    logo={true}
                    logoSrc={project.projectDetailsDescriptionLogosDTOs.map((img) => img.image)}
                    internal={true}
                    col1Lg={6}
                    col2Lg={4}
                  />
                </div>
              );
            })}
          </Container>
        </section>
      )}
      {projects?.result?.projectDetailsIncludesDtos ? (
        <section className="projectDetailsIncludesDtos">
          <Container>
            {projects?.result?.projectTitleEN && (
              <h3 className="mb-4">
                {i18n.language === "en" ? projects?.result?.projectTitleEN : projects?.result?.projectTitleAr}
              </h3>
            )}

            <Row className="justify-content-center">
              {projects?.result?.projectDetailsIncludesDtos?.map((project) => {
                return (
                  <Col lg={4} key={project.id} className="mb-4">
                    <LineBox
                      desc={`${i18n.language === "en" ? project?.titleEn : project?.titleAr}`}
                      pattern="/blueLine.png"
                      className="mainBgColor"
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      ) : null}

      {latestProjectSection && (
        <section className="latestProjectSection">
          <Container>
            <h4 className="mb-4"  style={{color: '#494949'}}> 
              {i18n.language === "en" ? latestProjectSection.shortDescriptionEn : latestProjectSection.shortDescriptionAr}
            </h4>
            {/* {latestProjectSection.project && (
              <div className="project-item">
                <h4>{i18n.language === "en" ? latestProjectSection.project.titleEn : latestProjectSection.project.titleAr}</h4>
                <p>{i18n.language === "en" ? latestProjectSection.project.descriptionEn : latestProjectSection.project.descriptionAr}</p>
              </div>
            )} */}
          </Container>
        </section>
      )}

      {projects?.result?.projectDetailsStatisticsDtos ? (
        <section className="projectDetailsStatisticsDtos">
          <Container>
            <Row className="justify-content-center w-75 m-auto">
              {projects?.result?.projectDetailsStatisticsDtos?.map((item) => (
                <Col lg={4} md={6} className="mb-4" key={item.id}>
                  <NumberBox
                    id={item.id}
                    title={item.number}
                    desc={`${i18n.language === "en" ? item.titleEn : item.titleAr}`}
                    img={item.image}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      ) : null}
    </>
  );
}

export default ProjectDetails;
