import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";



export const getDataBanner = createAsyncThunk("Subsidiaries/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Subsidiaries/headSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataOurSubsidiaries = createAsyncThunk("Subsidiaries/getDataOurSubsidiaries", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Subsidiaries/subsidiaries");
    return response.data;
    
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  banner: [],
  subsidiaries: [],
  isLoading: false,
  error: null,
};

export const subsidiariesSlice = createSlice({
  name: "subsidiaries",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataOurSubsidiaries.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataOurSubsidiaries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subsidiaries = action.payload;
      })
      .addCase(getDataOurSubsidiaries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default subsidiariesSlice.reducer;
