import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const getDataPage = createAsyncThunk("printingAcademy/getDataPage", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/EducationalInstitutions/GetEducationalInstitutionPrinting");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


const initialState = {
  dataPage: [],
  isLoading: false,
  error: null,
};

export const printingAcademySlice = createSlice({
  name: "printingAcademy",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataPage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dataPage = action.payload;
      })
      .addCase(getDataPage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      
  },
});

export default printingAcademySlice.reducer;
