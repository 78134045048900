import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const getDataBanner = createAsyncThunk("services/publishingSolutions/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get(
      "/api/OfferingPublishing/OfferingPublishingHeadSection"
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataServices = createAsyncThunk(
  "services/publishingSolutions/getDataServices",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.get(
        "/api/OfferingPublishing/GetOfferingCategoriesServices"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDataProducts = createAsyncThunk(
  "services/publishingSolutions/getDataProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.get(
        "/api/OfferingPublishing/GetOfferingCategoriesProducts"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  banner: [],
  services: [],
  products: [],
  isLoading: false,
  error: null,
};

export const publishingSolutions = createSlice({
  name: "publishingSolutions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataServices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.services = action.payload;
      })
      .addCase(getDataServices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;
      })
      .addCase(getDataProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default publishingSolutions.reducer;
