import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const getDataBanner = createAsyncThunk("socialImpact/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Sustainability/SustainabilityHeadSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataPositiveImpact = createAsyncThunk("socialImpact/getDataPositiveImpact", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Sustainability/SustainabilityText1");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataSteps = createAsyncThunk("socialImpact/getDataSteps", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Sustainability/GetSunSteps");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getDataPositiveImpact2 = createAsyncThunk("socialImpact/getDataPositiveImpact2", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Sustainability/SustainabilityText2");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getDataProjects = createAsyncThunk("socialImpact/getDataProjects", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Sustainability/GetSunProjects");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  banner: [],
  positiveImpact: [],
  steps: [],
  positiveImpact2: [],
  projects: [],
  values: [],
  ourFigures: [],
  isLoading: false,
  error: null,
};

export const socialImpactSlice = createSlice({
  name: "socialImpact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataPositiveImpact.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataPositiveImpact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.positiveImpact = action.payload;
      })
      .addCase(getDataPositiveImpact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataSteps.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataSteps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.steps = action.payload;
      })
      .addCase(getDataSteps.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataPositiveImpact2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataPositiveImpact2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.positiveImpact2 = action.payload;
      })
      .addCase(getDataPositiveImpact2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataProjects.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(getDataProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // .addCase(getDataValue.pending, (state) => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(getDataValue.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.values = action.payload;
      // })
      // .addCase(getDataValue.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.error = action.payload;
      // })
      // .addCase(getDataOurFigures.pending, (state) => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(getDataOurFigures.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.ourFigures = action.payload;
      // })
      // .addCase(getDataOurFigures.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.error = action.payload;
      // });
  },
});

export default socialImpactSlice.reducer;
