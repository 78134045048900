import React, { useEffect, useRef, useState } from "react";
import style from "./header.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLink from "../../utils/links/MainLink";
import { getDataWebsiteSearch } from "../../../app/search/searchSlice";

import {
  SidebarAboutUs,
  SidebarEducationalInstitutions,
  SidebarOffering,
  SidebarEducationalCertificates,
} from "../sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";


const Header = ({handleSidebarLinkClick, defaultActiveKey, onClick}) => {
  const [isScrolled, setIsScrolled] = useState();
  const [active, setActive] = useState(null);
  const [showSearch, setShowSearch] = useState(false)
  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
    const dispatch = useDispatch()

  // scroll function
  const ref = useRef();
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKey.trim()) {
      dispatch(getDataWebsiteSearch(searchKey)).then(() => {
        navigate(`search/${searchKey}`)
      });
    }
    setSearchKey("");
    setShowSearch(false)
  };

  useEffect(() => {
    const handleScroll = () => {
      if (ref && ref.current) {
        const scrolled = window.scrollY > ref.current.clientHeight;
        setIsScrolled(scrolled);
      }
    };

    if (ref && ref.current) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref && ref.current) {
        window.addEventListener("scroll", handleScroll);
      }
    };
 
  }, [ref,]);

  const showSearchHandler = () => {
    setShowSearch(!showSearch)
  }
  // switch active class 
  const activeClassHandle = (link) => {
    setActive(link);
    const ele = document.getElementById("basic-navbar-nav");
    if (ele !== null) {
      const hasClassShow = ele.classList.contains("show");

      if (hasClassShow) {
        ele.classList.remove("show");
      }
    }
  };


  // change language handler
  const { t, i18n } = useTranslation();

  const switchLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    if (newLanguage === "ar") {
      document.body.classList.add("ar");
    } else {
      document.body.classList.remove("ar");
    }
  };

  return (
    <>
      <header className={`${isScrolled ? "scrolled" : ""} ${style.header}`} ref={ref}>
        <Navbar expand="lg" className="p-0 w-100">

          {/* <Link to="/home" className="removeSm">
            <img src="/header_logo.png" alt="" className={style.header_logo} />
          </Link> */}
          <Link to="/home" className="removeSm">
          <img 
            src={i18n.language === "ar" ? "/header_logo_ar.png" : "/header_logo.png"} 
            alt="Logo" 
            className={style.header_logo} 
          />
        </Link>

          <div className="d-flex align-items-center">
            <FontAwesomeIcon onClick={showSearchHandler} icon={showSearch ? faX : faSearch} className="d-lg-none d-block remove" />

            <button
              onClick={switchLanguage}
              className={`d-lg-none d-block remove ${style.header_switch} ${style.header_list_item}`}
            >
              {t("switchLanguage")}
            </button> 
            <MainLink className={`d-lg-none d-block me-3 ms-3 `} to="/contact-us">
              {t("contact")}
            </MainLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <ul className={`list-unstyled ${style.header_list}`}>
                <li
                  className={`${isActive("home") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("home")}
                >
                  <Link to="/home">{t("home")}</Link>
                </li>
                <li
                  className={`${isActive("about") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("about")}
                >
                  {t("about")}
                </li>
                <li
                  className={`${isActive("solutions") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("solutions")}
                >

                  {t("solutions")}
                </li>

                <li
                  className={`${isActive("educational-institutions") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("EducationalInstitutions")}
                >
                  {t("EducationalInstitutions")}
                </li>
                <li
                  className={`${isActive("educational-certificates") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("EducationalCertificates")}
                >
                  {t("EducationalCertificates")}
                </li>

                <li
                  className={`${isActive("projects") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("projects")}
                >
                  <Link to="/projects">{t("projects")}</Link>
                </li>
                <li
                  className={`${isActive("news") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("news")}
                >
                  <Link to="/news">{t("news")}</Link>
                </li>
                <li
                  className={`${isActive("careers") && "activeLink"} ${style.header_list_item}`}
                  onClick={() => activeClassHandle("careers")}
                >
                  <a href="https://nahdetmisr.zohorecruit.com/jobs/Careers" target="_blank">
                    {t("careers")}
                  </a>
                </li>
              </ul>
            </Nav>
            <FontAwesomeIcon icon={showSearch ? faX : faSearch} onClick={showSearchHandler} className={`d-lg-block d-none remove ${style.header_search}`} />

            <button
              onClick={switchLanguage}
              className={`d-lg-block d-none remove header_switchLg ${style.header_switch} ${style.header_list_item}`}
            >
              {t("switchLanguage")}
            </button> 
            
            <MainLink className={`d-lg-block d-none ${i18n.language === "en" ? "ms-3" : "me-3"} remove`} to="/contact-us">
              {t("contact")}
            </MainLink>
          </Navbar.Collapse>
        </Navbar>
        {showSearch && <form className="searchNews mb-4 mt-3 remove" >
          <div className="searchNews_parent">
            <input
              className="searchNews_input"
              type="text"
              value={searchKey}
              placeholder={t("search")}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <button type="submit" className="searchNews_button" onClick={handleSearch}>
              <img src="/search.png" alt="Search" />
            </button>
          </div>
        </form>}

        {active === "about" && <SidebarAboutUs active={activeClassHandle}  />}
        {active === "EducationalInstitutions" && <SidebarEducationalInstitutions active={activeClassHandle}  />}
        {active === "solutions" && <SidebarOffering active={activeClassHandle} handleSidebarLinkClick={handleSidebarLinkClick} onClick={onClick} defaultActiveKey={defaultActiveKey} />}
        {active === "EducationalCertificates" && <SidebarEducationalCertificates active={activeClassHandle} />}
      </header>
    </>
  );
};

export default Header;