
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import { getDataProducts } from "../../../../../../app/solutions/k12/k12Slice";

import { useTranslation } from "react-i18next";
import InternalSection from "../../../../../../components/internalSection/InternalSection";
import Img from "../../../../../../components/utils/img/Img";
import Loading from "../../../../../../components/utils/portal/loading/Loading";
import Error from "../../../../../../components/utils/portal/error/Error";
import HalfSection7 from "../../../../../../components/halfSection7/HalfSection7";

function Curricula() {
  // fetch data
  const dispatch = useDispatch();
  const { isLoading, products, error } = useSelector((state) => state.k12);
  useEffect(() => {
    dispatch(getDataProducts());
  }, []);

  // change language handler
  const { i18n } = useTranslation();

  // loading
  if (isLoading) {
    return <Loading />;
  }
  // error
  if (error) {
    return <Error error={error.message} />;
  }
  return (
    <section className="Curricula pb-0 mb-0 pt-0" id="international-curricula-programs">
      <Container>
        {products?.result?.products.slice(3, 4)?.map((offer) => {
          return offer?.serviceCategoryServicesContents?.map((section) => {
            return (
              <>
                <InternalSection
                  title={`${i18n.language === "en" ? section?.titleEn : section?.titleAr}`}
                  img={section?.image}
                  // desc={`${i18n.language === "en" ? section?.descriptionEn : section?.descriptionAr}`}
                  key={section.id}
                  className="pt-0"
                  pt={true}
                />

                <div className="services">

                  {section?.serviceContentListDetails?.map((desc) => (
                    <Row>
                      <Col lg={8}>
                        <div className="content">
                          <h3 className="mb-4">{`${i18n.language === "en" ? desc?.titleEn : desc?.titleAr}`}</h3>
                          {/* <p>{i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr}</p> */}

                          <p dangerouslySetInnerHTML={{__html: i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr}} />

                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="content">
                          <Img src={desc?.image.replace("https://backend.nahdetmisr.com:10443/https://backend.nahdetmisr.com:10443/", "https://nahdatmasr.azurewebsites.net/")} className="img-fluid" alt="" />
                          {/* <Img src={desc?.image.replace("https://nahdatmasr.azurewebsites.net/", "")} className="img-fluid" alt="" /> */}

                        </div>
                      </Col>
                    </Row>
                  ))}


                </div>


              </>
            );
          });
        })}



      </Container>
      {products?.result?.products.slice(4, 7)?.map((offer) => {
        return offer?.serviceCategoryServicesContents?.map((section) => {
          return (
            <div className="evenOddSection4 evenOddSection mb-0 pb-lg-5 pt-lg-5 mt-4"  key={section.id}>
              <Container>
                <HalfSection7
                  id={section.id}
                  title={section.titleEn !== null && `${i18n.language === "en" ? section.titleEn : section.titleAr}`}
                  desc={`${i18n.language === "en" ? section.descriptionEn : section.descriptionAr}`}
                  img={section.image}
                  className={"p-0 mb-lg-0 mb-4 whtBg"}
                  col1Lg={7}
                  col2Lg={5}
                  transparent={true}
                  href={section.titleEn}
                  psOdd={true}
                />
              </Container>
            </div>
          );
        });

      })}
    </section>
  );
}

export default Curricula;
