import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../../../../components/utils/portal/error/Error";
import Loading from "../../../../../../components/utils/portal/loading/Loading";
import { getDataProducts } from "../../../../../../app/solutions/higherEducation/higherEducationSlice";
import { useTranslation } from "react-i18next";
import Img from "../../../../../../components/utils/img/Img";

function ProductSection() {
    // fetch data
    const dispatch = useDispatch();
    const { isLoading, products, error } = useSelector((state) => state.higherEducation);
    useEffect(() => {
        dispatch(getDataProducts());
    }, []);

    // change language handler
    const { i18n, t } = useTranslation();

    // loading
    if (isLoading) {
        return <Loading />;
    }
    // error
    if (error) {
        return <Error error={error.message} />;
    }
    return (
        <section className="newsDetails pt-5 ProductSection" id="elt">
            <Container>
                <div className="newsDetails_content">
                    {products?.result?.products?.map((product) => {
                        return product?.serviceCategoryServicesContents?.map((section) => {
                            return (
                                <div key={section.id}>
                                    <h2 className="ProductSection_content_title">
                                        {i18n.language === "en" ? section?.titleEn : section?.titleAr}
                                    </h2>
                                    <img src={section?.image} className="newsDetails_content_img" />
                                    {section?.serviceContentListDetails?.map((desc) => {
                                        return (
                                            <Row className="mt-4">
                                                <Col lg={8} className="order-lg-1 order-2">
                                                    <div className="newsDetails_contentBox">
                                                        <p dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? desc?.descriptionEn : desc?.descriptionAr }} />
                                                    </div>
                                                </Col>
                                                <Col lg={4} className="order-lg-2 order-1 mb-lg-0 mb-3">
                                                <Img src={desc?.image.replace("https://backend.nahdetmisr.com:10443/https://backend.nahdetmisr.com:10443/", "https://nahdatmasr.azurewebsites.net/")} className="img-fluid" alt="" />
                                                    {/* <Img src={desc?.image.replace("https://nahdatmasr.azurewebsites.net/", "")} className="img-fluid" alt="" /> */}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                            );
                        });
                    })}
                </div>
            </Container>
        </section>


    );
}

export default ProductSection;
 