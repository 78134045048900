import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";


// Thunk to fetch all news
export const getDataNews = createAsyncThunk("news/getDataNews", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/LatestNews/GetAll");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Thunk to fetch news details by ID
export const getDataNewsDetails = createAsyncThunk("news/getDataNewsDetails", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get(`/api/LatestNews/GetById?id=${id}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataNewsSearch = createAsyncThunk("news/getDataNewsSearch", async (searchKey, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get(
      `/api/LatestNews/searchNew?searchKey=${searchKey}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  news: [],
  newsDetails: null,
  newsSearch: [],
  isLoading: false,
  error: null,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    resetNewsSearch(state) {
      state.newsSearch = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataNews.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null; // Clear any previous error
        // Update the news state with the fetched data
        state.news = action.payload;
      })
      .addCase(getDataNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Add similar logic for other actions...
      .addCase(getDataNewsDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataNewsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newsDetails = action.payload;
      })
      .addCase(getDataNewsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataNewsSearch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataNewsSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newsSearch = action.payload;
      })
      .addCase(getDataNewsSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetNewsSearch } = newsSlice.actions;

export default newsSlice.reducer;