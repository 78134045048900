// import { lazy, useEffect } from "react";
// import Up from "../../../components/utils/up/Up";
// import Services from "./components/services/Services";
// import Products from "./components/products/Products";
// import { Tab, Tabs } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// const Banner = lazy(() => import("./components/banner/Banner"));




// const K12 = ({defaultActiveKey}) => {
//   const { hash } = useLocation();
//   const { t } = useTranslation();


//   useEffect(() => {
//     const handlePageLoad = () => {
//       if (hash) {
//         const element = document.getElementById(hash.substring(1));
//         if (element) {
//           element.scrollIntoView({ behavior: 'smooth' });
//         }
//       }
//     };
//     // Attach the handler to the window's load event
//     window.addEventListener('load', handlePageLoad);

//     // Cleanup the event listener
//     return () => {
//       window.removeEventListener('load', handlePageLoad);
//     };
//   }, [hash])
//   return (
//     <>
//       <Banner />
//       <Tabs
//         defaultActiveKey={defaultActiveKey === "Services" ? "Services" : "Products"}
//         id="uncontrolled-tab-example"
//       >
//         <Tab eventKey="Services" title={t("tapservices")}>
//           <Services />
//         </Tab>
//         <Tab eventKey="Products" title={t("tapproduct")}>
//           <Products />
//         </Tab>
//       </Tabs>
//       <Up />
//     </>
//   );
// };

// export default K12;


import { lazy, useEffect, useState, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Up from "../../../components/utils/up/Up";
import Services from "./components/services/Services";
import Products from "./components/products/Products";

const Banner = lazy(() => import("./components/banner/Banner"));

const K12 = () => {
  const { hash, state } = useLocation();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('services');
  const tabsRef = useRef(null);

  const scrollToTabs = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (state && state.activeTab) {
      setActiveTab(state.activeTab);
    }

    const handlePageLoad = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        scrollToTabs();
      }
    };

    window.addEventListener('load', handlePageLoad);

    // Scroll to tabs when component mounts or active tab changes
    scrollToTabs();

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [hash, state, activeTab]);

  return (
    <>
      <Banner />
      <div ref={tabsRef}>
        <Tabs
          activeKey={activeTab}
          id="k12-tabs"
          className="mb-3"
          onSelect={(k) => setActiveTab(k)}
        >
          <Tab eventKey="services" title={t("services")}>
            <Services />
          </Tab>
          <Tab eventKey="products" title={t("Products")}>
            <Products />
          </Tab>
        </Tabs>
      </div>
      <Up />
    </>
  );
};

export default K12;