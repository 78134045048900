import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const getDataBanner = createAsyncThunk("examCenter/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Certificates/headSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataSecondSection = createAsyncThunk("examCenter/getDataSecondSection", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Certificates/secondSection");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataAllCertificates = createAsyncThunk("examCenter/getDataAllCertificates", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get("/api/Certificates/GetAll");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const initialState = {
  banner: [],
  secondSection: [],
  certificates: [],
  isLoading: false,
  error: null,
};

export const examCenterSlice = createSlice({
  name: "examCenter",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataSecondSection.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataSecondSection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.secondSection = action.payload;
      })
      .addCase(getDataSecondSection.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDataAllCertificates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataAllCertificates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.certificates = action.payload;
      })
      .addCase(getDataAllCertificates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default examCenterSlice.reducer;
