import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const getDataBanner = createAsyncThunk("services/corporates/getDataBanner", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.get(
      "/api/OferringCorporates/OfferingHeadSection"
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getDataCorporates = createAsyncThunk(
  "services/corporates/getDataCorporates",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.get(
        "/api/OferringCorporates/GetOfferingCategoriesServices"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  banner: [],
  corporates: [],
  isLoading: false,
  error: null,
};

export const corporates = createSlice({
  name: "corporates",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDataBanner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataBanner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banner = action.payload;
      })
      .addCase(getDataBanner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getDataCorporates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDataCorporates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.corporates = action.payload;
      })
      .addCase(getDataCorporates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

  },
});

export default corporates.reducer;
